<template>
<div class="mobile-page-layout">

    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">

                <router-link :to="{ name: 'NewTechnical'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>

                <div class="mobile-page-header-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="header-svg-icon">
                        <path d="M160 320c53.02 0 96-42.98 96-96c0-53.02-42.98-96-96-96C106.1 128 64 170.1 64 224C64 277 106.1 320 160 320zM192 352H128c-70.69 0-128 57.3-128 128c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32C320 409.3 262.7 352 192 352z" class="fa-primary"></path>
                        <path d="M592 0h-384C181.5 0 160 22.25 160 49.63V96c23.42 0 45.1 6.781 64 17.8V64h352v288H287.3c22.07 16.48 39.54 38.5 50.76 64h253.9C618.5 416 640 393.8 640 366.4V49.63C640 22.25 618.5 0 592 0zM312 218l55.01 54.99c9.375 9.375 24.6 9.375 33.98 0L473 201l24.25 24.25C508.6 236.6 528 228.6 528 212.5V124c0-6.625-5.375-12-12-12h-88.5c-16.12 0-24.12 19.38-12.75 30.75L439 167L384 222l-55.01-55.01c-9.375-9.375-24.6-9.375-33.98 0L280.3 181.8C285 195.1 288 209.1 288 224c0 6.875-.9999 13.38-2 20L312 218z" class="fa-secondary"></path>
                    </svg>
                </div>
                <div class="mobile-page-header-text">
                    تابلو تحلیل‌ها
                </div>  
            </div>
            <div class="mobile-page-header-actions">
                <div class="mobile-page-header-icon pointer" @click.prevent="open_new_technical_modal()">
                    <i class="header-uil-icon uil uil-plus-circle"></i>
                </div>      
                <div class="mobile-page-header-icon pointer" @click.prevent="filter = !filter">
                    <i class="header-uil-icon uil uil-filter" style="font-size: 24px; padding: 5px 3px;"></i>
                </div>
                
                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div>

    <div v-if="filter" class="mobile-page-filter-box">        
        <div class="widget-tabs-btn flex-btn">
            <div :class="'tgju-btn tgju-btn-light-red m-left m-bottom-mobile ' + (hot ? 'active' : '') " @click="selectHot()">
                <i class="uil uil-fire color-red"></i>
                <span> داغ‌ترین‌ها</span>
            </div>
            <div v-if="this.$helpers.userAuth()" :class="'tgju-btn tgju-btn-light-orange m-left m-bottom-mobile ' + (selectedMyTechnicals ? 'active' : '') " @click="selectMyTechnicals()">
                <i class="uil uil-chart"></i>
                <span class="hide-340"> تحلیل‌های من</span>
            </div>
            <span v-if="this.$helpers.userAuth()" @click="selectBookmark()" :class="'tgju-btn tgju-btn-dark-yellow ' + (bookmark ? 'active' : '')">
                <i class="uil uil-bookmark-full "></i>
                <span class="hide-m"> بوک‌مارک‌ها</span>
            </span>
        </div>

        <v-select v-model="selected" @input="selectFilter()" class="profile-mobile-view-select mobile-view-select-full mobile-view-select-btn" :items="[{key: 'newest', value: 'جدیدترین'},{key: 'oldest', value: 'قدیمی ترین'}]" item-text="value"  item-value="key" label="الویت" outlined></v-select>
        <v-select v-model="sortByTime" @input="selectTime()" class="profile-mobile-view-select mobile-view-select-full mobile-view-select-btn" :items="[{key: 'all', value: 'بازه زمانی'},{key: 'day', value: 'روز گذشته'},{key: 'week', value: 'هفته گذشته'},{key: 'month', value: 'ماه گذشته'},{key: '3-month', value: '۳ ماه گذشته'},{key: '6-month', value: '۶ ماه گذشته'},{key: 'year', value: 'سال گذشته'}]" item-text="value"  item-value="key" label="الویت" outlined></v-select>
        <v-select v-model="selectedMarket" @input="selectFilter()" class="profile-mobile-view-select mobile-view-select-full mobile-view-select-btn" :items="technicalsCategoryList" item-text="value"  item-value="key" label="الویت" outlined></v-select>

    </div>

    <PublicTechnicalList :filter="selected" :hot="selectedHot" :my_technicals="selectedMyTechnicals" :time="sortByTime" :market="selectedMarket" :bookmark="bookmark" :key="componentReload"></PublicTechnicalList>

</div>
</template>

<script>
// این ویو برای تحلیل های عمومی مورد استفاده قرار میگیرد
import PublicTechnicalList from '@/components/Mobile/Technical/PublicTechnicalList.vue'

export default {
    name: 'PublicTechnicalMobileView',
    components: {
        PublicTechnicalList,
    },
    data: function () {
        return {
            filter:false,
            technicalsCategoryList: [{key: 'all', value: 'بازار معاملاتی'}],
            showBackButton: false,
            componentReload: 0,
            selected: 'newest',
            selectedHot: '',
            sortByTime: 'all',
            selectedMarket: 'all',
            selectedMyTechnicals: false,
            bookmark: false,
            hot: false,
            widget: {},
            data: {},
            menus: [
                { title: 'تابلو تحلیل‌ها', modal: 'technicals' },
                { title: 'تحلیل‌گرها', modal: 'analysts' },
                { title: 'راهنما', modal: 'help' }
            ],
        }
    },
    mounted () {
        this.load_technicals_category_list();
        if (this.$router.history._startLocation == '/technical'){
            this.showBackButton = true;
        }
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        load_technicals_category_list() {
            this.$helpers
                .makeRequest("GET", '/technical/technicals-category-list')
                .then((api_response) => {
                    api_response.data.response.category.forEach(element => {
                        this.technicalsCategoryList.push({key: element.slug_en, value: element.name});
                    });
                    
                });
        },
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }else if(name == 'technicals'){
                this.$router.push({ name : 'PublicTechnical' });
            }else if(name == 'analysts'){
                this.$router.push({ name : 'PublicAnalysts' });
            }
        },
        // این متد جهت باز کردن یک مدال برای افزودن تحلیل جدید استفاده می شود
        open_new_technical_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_NewTechnical_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'NewTechnical.vue',
                config: {
                    title: 'تحلیل جدید',
                    icon: 'uil uil-plus-circle with-icon',
                    smallModal: false,
                    data: {
                        // جهت ریفرش کردن مدال که از داده های قدیمی پاک شود
                        refresh: Math.random(),
                    },
                }
            };

            this.$root.$emit('open_modal3', modal_data);

            // وقتی روی دکمه بستن مدال کلیک شد
            // در کال بک فانشکن لیست را آپدیت میکنیم . 
            this.$root.$on(callbackEvent, (response) => {
                this.refresh++;
            });

            $('body').addClass('show-modal3');
        },
        // این متد یک اتریبوبت را کامپوننت لیست تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل هایی که بو مارک شده اند
        selectBookmark() {
            if (this.bookmark) {
                this.bookmark = false;
            } else if (!this.bookmark) {
                this.bookmark = true;
            }
            this.componentReload = Math.random();
        },
        // این متد یک اتریبوبت را کامپوننت لیست تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        // قدیمی ترین ، محبوب ترین ، جدیدترین
        selectFilter() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        selectTime() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        // این متد یک اتریبوبت را کامپوننت مارکت تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        selectMarket() {
            this.selectedHot = '';
            this.componentReload = Math.random();
        },
        // متد برای نمایش داغ ترین یا همان محبوب ترین تحلیل ها
        selectHot() {
            if (this.hot) {
                this.selectedHot = '';
                this.hot = false;
            } else if (!this.hot) {
                this.selectedHot = 'popular';
                this.hot = true;
            }
            this.componentReload = Math.random();
        },
        selectMyTechnicals() {
            if (this.selectedMyTechnicals) {
                this.selectedHot = '';
                this.selectedMyTechnicals = false;
            } else if (!this.selectedMyTechnicals) {
                this.selectedHot = 'popular';
                this.selectedMyTechnicals = true;
            }
            this.componentReload = Math.random();

        },
        // دکمه بازگشت
        back() {
            this.$router.push({ name : 'NewTechnical' });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Technical.vue',
                title: 'تحلیل‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
